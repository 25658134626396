import * as React from "react";
import Layout from "../../components/layout/Layout";
import SplashBanner from "../../components/SplashBanner";
import {infoBlock} from "../../scss-modules/terms.module.scss";

const bannerImg = '../../banners/about-us_banner.jpg';


//RENDER
export default function TermsAndConditionsPage() {
    return (
        <Layout pageTitle={'Terms and Conditions'}>
            <SplashBanner
                title={"Terms and Conditions"}
                content={''}
                backgroundImg={bannerImg}
            />

            <section id="termsAndConditions">
                <div className="container">
                    <p>1.1 These Terms and Conditions strictly apply to the use of the website
                        www.Performance-energy.com,
                        for specific written terms such as Client &amp; Consultant service agreements, please contact <a
                            href="mailto:info@performance-energy.com"
                            target="_blank" rel="noopener noreferrer">info@performance-energy.com</a>.
                    </p>
                    <p>1.2 We strongly recommend that all Terms and Conditions are read in-depth before engaging in a
                        service agreement with Performance Energy or using our website &amp; linked sites.</p>
                    <p>1.3 By continuing to obtain service from Performance Energy or continuing to access the website,
                        you
                        are confirming that you have read the terms and conditions, agree to them and will be bound by
                        these
                        terms.</p>
                    <p>1.4 If you do not agree or are not sure of any terms mentioned, please do not continue to use the
                        website or service until you have raised the concern with <a
                            href="mailto:info@performance-energy.com"
                            target="_blank" rel="noopener noreferrer">info@performance-energy.com</a>.
                    </p>
                    <p>1.5 Our Terms &amp; Conditions are subject to change; therefore, we recommend you check the
                        Terms &amp; Conditions regularly to ensure that you are aware of the latest version.</p>
                    <div className={infoBlock}>
                        <h3>2.0 Use of this website</h3>
                        <p>2.1 The use of this website shall be for lawful purposes only. You must not use the website
                            in
                            any
                            way that breaches any applicable law, may it be local or international or which is in any
                            way
                            unlawful or fraudulent.</p>
                        <p>2.2 Information on this website is not for distribution of any kind and may only be used for
                            personal
                            viewing. Should you wish to distribute any part of the Company’s website, please contact the
                            CEO
                            to
                            receive authorisation before doing so.</p>
                        <p>2.3 You must not for any reason:</p>
                        <p>2.3.1 Distribute information on this website</p>
                        <p>2.3.2 Copy or rephrase the material of this website (this includes copying, rephrasing,
                            altering
                            the
                            wording, altering the design, transferring any content, in whole or in part (Including deep
                            linking)</p>
                        <p>2.3.3 Use any of the website data to replicate the companies services, design or working on
                            any
                            other
                            media platform.</p>
                        <p>2.4 You must at all times</p>
                        <p>2.4.1 Only supply accurate and honest information to the company when you register for its
                            services
                            or apply for positions, submit information via company links.</p>
                        <p>2.5 Registering for our services, in no way guarantees that we will supply your our
                            services.</p>
                    </div>
                    <div className={infoBlock}>
                        <h3>3.0 Cookie &amp; Privacy Policies</h3>
                        <p>3.1 These terms are in conjunction with our cookie &amp; Privacy Policies and should be read
                            in
                            full
                            before committing to a service with us, or continuing with our website.</p>
                    </div>
                    <div className={infoBlock}>
                        <h3>4.0 Our information</h3>
                        <p>4.1 Performance Energy registered in the UAE, is the sole operator of this website ("the
                            Company",
                            "us", "We").</p>
                        <p>4.2 Performance Energy registered in the UAE</p>
                    </div>
                    <div className={infoBlock}>
                        <h3>5.0 Licencing &amp; Ownership</h3>
                        <p>5.1 The Company is the lawful owner of all content, images &amp; data in the website. Any
                            copying,
                            transferring, altering of this data is strictly prohibited.</p>
                        <p>5.2 We are the sole owner of the website, including copyright design rights.</p>
                        <p>5.3 All rights are to be assigned to us on a global basis should you acquire any rights in or
                            to
                            our
                            website or the materials on it.</p>
                        <p>5.4 All moral rights are also to be unconditionally and irrevocably waived to us in the
                            eventuality
                            that you acquire our data or breach our terms.</p>
                        <p>5.6 We try to ensure that the website &amp; data is as accurate as possible; however, are
                            unable
                            to
                            guarantee the accuracy or completeness of all of the information presented. Please
                            contact <a
                                href="mailto:info@performance-energy.com"
                                target="_blank" rel="noopener noreferrer">info@performance-energy.com</a> should you have any queries regarding
                            the
                            data.
                        </p>
                        <p>5.7 All data, logos, phrases, graphics, images, designs, layouts, are constituted as
                            trademarks
                            of
                            Performance Energy regardless if they are registered or unregistered.</p>
                    </div>
                    <div className={infoBlock}>
                        <h3>6.0 Changes</h3>
                        <p>6.1 The website may be suspended without notice in the eventuality that we feel there is a
                            security
                            risk to the business and those who access it or if the data needs updating.</p>
                        <p>6.2 We reserve the right to remove, change or amend any details or data within our website
                            without
                            prior notice or justification.</p>
                    </div>
                    <div className={infoBlock}>
                        <h3>7.0 Hacking &amp; Viruses</h3>
                        <p>7.1 It is a breach of the company Terms and Conditions to be involved in any way in the
                            attempt
                            to
                            hack or insert a virus into the Companies database, website, any other company property or
                            third
                            party software used in conjunction with the Companies activities. All attempts will be
                            considered a
                            criminal offence under the Computer Misuse Act 1990. All damages sustained will be payable
                            by
                            the
                            offender.</p>
                        <p>7.2 Viruses &amp; hacking may include (but are not limited to) introducing viruses, worms,
                            logic
                            bombs, trojans, or other harmful materials that may affect the computer, program, software
                            or
                            company operation.</p>
                        <p>7.3 We scan our website weekly for harmful content, but cannot guarantee that the
                            website &amp; company links are free from viruses or harmful material and therefore will not
                            be
                            held
                            liable for any losses or damages suffered due to a result of using our
                            website &amp; content.
                            All
                            data &amp; information supported by Performance Energy constitutes as content.</p>
                    </div>
                    <div className={infoBlock}>
                        <h3>8.0 Website Linking &amp; RSS</h3>
                        <p>8.1 You must not create any links to or from the website</p>
                        <p>8.2 Our website may include links to third parties &amp; external links; these are provided
                            purely to
                            enhance the experience for our users and should not be replicated or copied.</p>
                        <p>8.3 We are liable for any losses or damages that you incur as a result of the content of any
                            websites
                            which you access through our website. You shall hold us harmless of all costs &amp; damages
                            as
                            part
                            of these terms and conditions.</p>
                        <p>8.4 We are not responsible for any RSS content provided.</p>
                    </div>
                    <div className={infoBlock}>
                        <h3>9.0 Liabilities</h3>
                        <p>9.1 No guarantees, warranties or conditions are provided about the accuracy of the content of
                            this
                            website and any sub-content linked to it.</p>
                        <p>9.2 We exclude liability for any loss or damage incurred by you as a result of reliance of
                            the
                            information provided by this website.</p>
                    </div>
                    <div className={infoBlock}>
                        <h3>10 Jurisdiction and Applicable Law</h3>
                        <p>10.1 These terms and conditions are bound and governed in accordance with the laws of DTWC FZ
                            UAE.</p>
                        <p>If you have any questions, please contact <a href="mailto:info@performance-energy.com"
                                                                        target="_blank" rel="noopener noreferrer">info@performance-energy.com</a>
                        </p>
                        <p>If you do not agree to these terms, please leave the website now.</p>
                    </div>
                </div>
            </section>


        </Layout>
    )
}
